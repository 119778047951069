        // SETTING BRANDCONFIG CONFIGURATION TO USE IN SCSS VARIABLES // whitelabel.scss
document.documentElement.style.setProperty('--utapPrimaryColor', `#E00700`);
document.documentElement.style.setProperty('--utapSecondaryColor', `#f6f6f7`);
document.documentElement.style.setProperty('--utapLightRedSecondaryColor', `#FFCCCB`);
        // ENDS
export default {
    "brandName" : "UTap",
    "brandLogo" : "https://quebuster.s3.ap-south-1.amazonaws.com/website/assets/utap-logo3.webp",
    "brandPrimaryColor":'#E00700',
    "brandSecondaryColor":"#f6f6f7",
    "brandLightRedSecondaryColor":"#FFCCCB",
    "brandHeaderColor":'#000000',
    "loginScreen": {
        "brandLogo": "https://quebuster.s3.ap-south-1.amazonaws.com/website/assets/utap-logo1.webp",
        "headerLogo": "https://quebuster.s3.ap-south-1.amazonaws.com/website/assets/utap-logo2.webp",
        "headerColor": "#000",
        "primaryColor": "#fff",
        "secondaryColor": "#E00700",
        "logoWidth": "100px",
        "headerLogoWidth": "80px"
    },
    "sidebarIcons":{
      "home":require("../icons/HomeUtap.svg"),
      "catalog":require("../icons/catalogUtap.svg"),
      "inventory":require("../icons/InventoryUtap.svg"),
      "purchase":require("../icons/PurchaseUtap.svg"),
      "customer":require("../icons/CustomerUtap.svg"),
      "reports":require("../icons/reportsUtap.svg"),
      "settings":require("../icons/settingsUtap.svg"),
      "estore":require("../icons/estoreUtap.svg"),
      "khata":require("../icons/KhataUtap.svg"),
      "developer":require("../icons/devhooksUtap.svg"),
      "integrations":require("../icons/integrationUtap.svg"),
      "billing":require("../icons/BillingUtap.svg"),
      "appointments":require("../icons/AppointmentUtap.svg"),
      "urbanpiper":require("../icons/urbanpiperUtap.svg"),
      "kds":require("../icons/KDSUtap.svg"),
      "expense":require("../icons/expenseUtap.svg"),
      "reservation":require("../icons/reservation_utap.svg"),
    },
    "miscIcons": {
        "downArrow":require("../icons/dropdownarrowUtap.svg"),
        "backArrow":require("../icons/dropbackarrowUtap.svg"),
        "editIcon":require("../icons/utapedit.svg"),
        "plusIcon":require("../icons/utapplus.svg"),
        "crossIcon":require("../icons/cross-utap.svg"),
        "addCustomer":require("../icons/add-customer-utap.svg"),
        "trash":require("../icons/trash-utap.svg"),
        "sortIcon":require("../icons/table-sort-arrow-utap.svg"),
        "gridIcon":require("../icons/display-toggle-utap.svg"),
        "listIcon":require("../icons/List-utap.svg"),
        "plusIcon":require("../icons/plus-utap.svg"),
        "minusIcon":require("../icons/minus-utap.svg"),
        "filterIcon":require("../icons/filter-utap.svg"),
        "success":require("../icons/success-utap.svg"),
        "calendar":require("../icons/calendar-utap.svg"),
    },
    suspenseLoaderUrl: "https://quebuster.s3.ap-south-1.amazonaws.com/website/assets/ezgif.com-speed+(2).gif",
    filter: "invert(15%) sepia(81%) saturate(5245%) hue-rotate(10deg) brightness(88%) contrast(120%)",
    languageOptions:["en-ar","ar"]
}
