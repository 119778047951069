import {FETCH_RTP_SUCCESS, FETCH_DSR_SUCCESS, FETCH_DPB_SUCCESS, FETCH_DOB_SUCCESS, CHECK_LIST_OF_ORDER_SUCCESS, CHECK_EMPLOYEE_WISE_SALES_SUCCESS, FETCH_DISCOUNT_ORDER_REPORT_SUCCESS,FETCH_PRODUCT_WISE_SALES_SUCCESS, FETCH_ONLINE_ORDER_REPORT_SUCCESS, FETCH_PRODUCT_IN_ONLINE_ORDER_REPORT_SUCCESS, FETCH_ONLINE_ORDER_DETAIL_REPORT_SUCCESS, FETCH_STORE_SALES_TRANSACTION_TRACKER_SUCCESS,FETCH_ONLINE_ORDER_WISE_PAYMENT_BREAKUP_SUCCESS,FETCH_ORDER_PAYMENT_SUMMARY_WEB_SUCCESS,FETCH_HSNSAC_WISE_TAX_BREAKUP_SUCCESS, FETCH_ORDER_COMBO_REPORT_SUCCESS, FETCH_SUB_CATEGORY_SALES_SUCCESS, FETCH_GET_STORE_HOURLY_SALES_SUCCESS} from '../actions/types';

export default {
    RTP: function(state = null, action) {
        switch(action.type) {
            case FETCH_RTP_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    DSR: function(state = null, action) {
        switch(action.type) {
            case FETCH_DSR_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    DPB: function(state = null, action) {
        switch(action.type) {
            case FETCH_DPB_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    DOB: function(state = null, action) {
        switch(action.type) {
            case FETCH_DOB_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    COI: function(state = null, action) {
        switch(action.type) {
            case CHECK_LIST_OF_ORDER_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    EWS: function(state = null, action) {
        switch(action.type) {
            case CHECK_EMPLOYEE_WISE_SALES_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    ODR: function(state = null, action) {
        switch(action.type) {
            case FETCH_DISCOUNT_ORDER_REPORT_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    PWS: function(state=null, action){
        switch(action.type){
            case FETCH_PRODUCT_WISE_SALES_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    OOR: function(state=null, action){
        switch(action.type){
            case FETCH_ONLINE_ORDER_REPORT_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    PIOOR: function(state=null, action){
        switch(action.type){
            case FETCH_PRODUCT_IN_ONLINE_ORDER_REPORT_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    OODR: function(state=null, action){
        switch(action.type){
            case FETCH_ONLINE_ORDER_DETAIL_REPORT_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    STD: function(state=null, action){
        switch(action.type){
            case FETCH_STORE_SALES_TRANSACTION_TRACKER_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    OOWPB:function(state=null, action){
        switch(action.type){
            case FETCH_ONLINE_ORDER_WISE_PAYMENT_BREAKUP_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    
    OPSR:function(state=null, action){
        switch(action.type){
            case FETCH_ORDER_PAYMENT_SUMMARY_WEB_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    
    HSNSACWTB:function(state=null, action){
        switch(action.type){
            case FETCH_HSNSAC_WISE_TAX_BREAKUP_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    OCR:function(state=null, action){
        switch(action.type){
            case FETCH_ORDER_COMBO_REPORT_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    SCS:function(state=null, action){
        switch(action.type){
            case FETCH_SUB_CATEGORY_SALES_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    GSHS:function(state=null, action){
        switch(action.type){
            case FETCH_GET_STORE_HOURLY_SALES_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
}

